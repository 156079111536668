import bootbox from 'bootbox';

import { api } from 'JIX/utils.js';

import { __ } from 'JIX/gettext.js';

async function contact(form) {
    const data = new FormData(form);
    return api('POST', '/virksomhedsbruger/kontakt.json', data, { processData: false, contentType: false });
}

function openContactBox() {
    const formTemplate = document.getElementById('companyuser-contact-template');
    const formContent = formTemplate.content.cloneNode(true);
    const form = document.createElement('form');
    form.append(formContent);

    const button = form.querySelector('.btn');
    button.classList.add('btn-sm', 'd-block', 'ml-auto');

    const $modal = bootbox.dialog({
        title: __('Bliv ringet op'),
        message: form,
        backdrop: true,
    });

    form.addEventListener('submit', async (event) => {
        event.preventDefault();
        try {
            button.disabled = true;
            button.style.cusor = 'wait';

            await contact(form);

            $modal.modal('hide');
        } catch (error) {
            button.disabled = false;
            button.style.cusor = null;
            throw error;
        } finally {
            window.jixAnalytics('event', { category: 'kontaktformular', action: 'click', label: 'Bliv ringet op' });
        }
    });
}

document.querySelectorAll('.jix-companyuser-contact').forEach((link) => {
    link.addEventListener('click', (event) => {
        event.preventDefault();
        openContactBox();
    });
});
